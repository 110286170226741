import { Injectable, NgModule, OnDestroy, signal } from '@angular/core';
import { WatchQueryFetchPolicy } from '@apollo/client/core';
import {
  DeleteMediaGQL,
  DeleteMediaListGQL,
  GetMediaForMediaManagePageGQL,
  GetProfileMediaForMediaListGQL,
  GetProfileMediaForMediaListQuery,
  GetProfileMediaForMediaListQueryVariables,
  GetUserMediaForMediaListGQL,
  GetUserMediaForMediaListQuery,
  GetUserMediaForMediaListQueryVariables,
  Maybe,
  Media,
  MediaDetailedFragment,
  MediaFiltersInput,
  MediaForMediaListFragment,
  MediaVisibilityType,
  MoveMediaFolderGQL,
  MoveMediaFolderInput,
  ResourceType,
  SaveMediaFromCloudGQL,
  SaveMediaInput,
} from '@designage/gql';
import { DeleteMediaDialogComponent } from '@desquare/components/common/src/media/delete-media-dialog/delete-media-dialog.component';
import { MediaReadableType } from '@desquare/enums';
import {
  IGetMediaByProfileParams,
  IGetMediaByUserParams,
  IMediaFilterButtonGroupOutput,
  IMediaForMediaList,
} from '@desquare/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryRef } from 'apollo-angular';
import { filter } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map,
  Observable,
  take,
  tap,
} from 'rxjs';
import { FilterService } from '../filter/filter.service';
import { FolderService } from '../folder/folder.service';
import { ToasterService } from '../toaster/toaster.service';
import { lazyLoadService } from '@desquare/utils';

@Injectable({
  providedIn: 'root',
  // note: using providedIn: 'any', makes this service's instance to be
  // shared among all the eagerly loaded modules
  // more info: https://stackoverflow.com/a/72561645
})
export class MediaService implements OnDestroy {
  private getMediaByProfileQuery?: QueryRef<
    GetProfileMediaForMediaListQuery,
    GetProfileMediaForMediaListQueryVariables
  >;

  private getMediaByUserQuery?: QueryRef<
    GetUserMediaForMediaListQuery,
    GetUserMediaForMediaListQueryVariables
  >;

  private folderService$ = lazyLoadService(() =>
    import('../folder/folder.service').then((m) => m.FolderService)
  );

  DEFAULT_MEDIA_VISIBILITY_FILTER: MediaVisibilityType[] = [
    MediaVisibilityType.Default,
    MediaVisibilityType.Template,
  ];
  folderIds = signal<string[]>([]);

  constructor(
    private getMediaGQL: GetMediaForMediaManagePageGQL,
    private getProfileMediasGQL: GetProfileMediaForMediaListGQL,
    private getUserMediasGQL: GetUserMediaForMediaListGQL,
    private saveMediaGQL: SaveMediaFromCloudGQL,
    private deleteMediaListGQL: DeleteMediaListGQL,
    private moveMediaFolderGQL: MoveMediaFolderGQL,
    private filterService: FilterService,
    private toasterService: ToasterService,
    // private folderService: FolderService,
    private modalService: NgbModal
  ) {}

  ngOnDestroy(): void {
    // console.log('MEDIA SERVICE INSTANCE DESTROYED'); // DEBUG
    this.resetQueries();
  }

  getMediaByProfile({
    profileId,
    filters,
    options,
    globalSearch = false,
  }: IGetMediaByProfileParams) {
    const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network';

    const defaultFilters: MediaFiltersInput = {
      ...filters,
      name: globalSearch ? filters?.name : undefined,
      folderId: globalSearch ? undefined : filters?.folderId,
      mediaVisibility:
        filters?.mediaVisibility ?? this.DEFAULT_MEDIA_VISIBILITY_FILTER,
    };

    // console.log('defaultFilters: ', defaultFilters); // DEBUG

    if (!this.getMediaByProfileQuery) {
      this.getMediaByProfileQuery = this.getProfileMediasGQL.watch(
        { profileId, filters: defaultFilters, options },
        {
          fetchPolicy,
          errorPolicy: 'all',
        }
      );
    } else {
      this.getMediaByProfileQuery.setVariables({
        profileId,
        filters: defaultFilters,
        options,
      });
    }

    // console.log('QUERY ID: ', this.getMediaByProfileQuery.queryId); // DEBUG

    return this.getMediaByProfileQuery.valueChanges.pipe(
      map(({ data, loading, errors }) => {
        if (!data.profile) return [];

        const { results, total } = data.profile.media;

        // console.log('getMediaByProfileQuery: ', data); // DEBUG

        const mediaForMediaList: IMediaForMediaList[] = results.map((x) =>
          this.setMedias(x)
        );

        if (!globalSearch) {
          const filteredMediaList = this.filterMedias({
            medias: mediaForMediaList,
            searchText: filters?.name ?? undefined,

            // optional TODO: fix the client side filter for mediaVisibilityType and resourceType
            // filterList: modifiedFilters,
          });

          return filteredMediaList;
        }

        return mediaForMediaList;
      })
    );
  }

  async getMediaById(id: string) {
    const { data } = await lastValueFrom(
      this.getMediaGQL.fetch(
        {
          id,
        }
        /*,{ fetchPolicy: 'no-cache', }*/
      )
    );

    return data?.media as Maybe<MediaDetailedFragment>;
  }

  async getProfileMediaForCreativeEditor(profileId: string, groupId?: string) {
    const { data } = await lastValueFrom(
      this.getProfileMediasGQL.fetch(
        {
          profileId,
          filters: {
            pageSize: 500,

            // if the folderId (group) is 'root' then we get the medias in root
            // note: medias that have the folderId === null are considered in root
            folderId: groupId === 'root' ? null : groupId,
          },
        },
        {
          fetchPolicy: 'no-cache',
          // note: using cache causes getting stale data here, to reproduce staleness:
          // 1. move media to a different folder
          // 2. open profile images in creative editor
        }
      )
    );

    return data.profile?.media.results as Maybe<Media[]>;
  }

  getMediaByUser({
    userId,
    filters,
    options,
    globalSearch = false,
  }: IGetMediaByUserParams) {
    // optional TODO: currently getMediaByProfile() and getMediaByUser()
    // looks very DRY (Don't Repeat Yourself), the only difference is:
    // 1. getMediaByUser() input: userId,  getMediaByProfile() input: profileId
    // 2. getMediaByUser() query: getUserMediasGQL, getMediaByProfile() query: getProfileMediasGQL
    // lets try to find a way to unite them or at least unite the common code later
    const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network';

    const defaultFilters: MediaFiltersInput = {
      ...filters,
      name: globalSearch ? filters?.name : undefined,
      folderId: globalSearch ? undefined : filters?.folderId,
      mediaVisibility: filters?.mediaVisibility
        ? filters.mediaVisibility
        : [MediaVisibilityType.Default, MediaVisibilityType.Template],
    };

    // console.log('defaultFilters: ', defaultFilters); // DEBUG

    if (!this.getMediaByUserQuery) {
      this.getMediaByUserQuery = this.getUserMediasGQL.watch(
        { userId, filters: defaultFilters, options },
        {
          fetchPolicy,
          errorPolicy: 'all',
        }
      );
    } else {
      this.getMediaByUserQuery.setVariables({
        userId,
        filters: defaultFilters,
        options,
      });
    }

    return this.getMediaByUserQuery.valueChanges.pipe(
      map(({ data, loading, errors }) => {
        if (!data.user) return [];

        const results = data.user.media;
        if (!results) return [];

        // console.log('getMediaByProfileQuery: ', data); // DEBUG

        const mediaForMediaList: IMediaForMediaList[] = results.map((x) =>
          this.setMedias(x)
        );

        if (!globalSearch) {
          const filteredMediaList = this.filterMedias({
            medias: mediaForMediaList,
            searchText: filters?.name ?? undefined,

            // optional TODO: fix the client side filter for mediaVisibilityType and resourceType
            // filterList: modifiedFilters,
          });

          return filteredMediaList;
        }

        return mediaForMediaList;
      })
    );
  }

  setMedias(media: MediaForMediaListFragment): IMediaForMediaList {
    if (media.metadata) {
      const downloadLink = media.secureUrl.replace(
        'upload/',
        'upload/fl_attachment/'
      );
      const dimensions = media.metadata.width
        ? `${media.metadata.width} x ${media.metadata.height}`
        : 'N/A';
      const readableType = this.getReadableType(media);

      return {
        ...media,
        dimensions,
        downloadLink,
        readableType,
      };
    } else {
      // prevent multiple concatenation when table is reloaded
      const name = `${media.name.replace(' (missing)', '')} (missing)`;
      const partialMedia: IMediaForMediaList = {
        ...media,
        name,
      };

      // console.log('partialMedia: ', partialMedia); // DEBUG

      return partialMedia;
    }
  }

  filterMedias({
    medias,
    searchText,
    filterList,
  }: {
    medias: IMediaForMediaList[];
    searchText?: string;
    filterList?: IMediaFilterButtonGroupOutput[];
  }) {
    // filter by media filter button group
    const filteredMedias: IMediaForMediaList[] =
      !filterList || filterList.length === 0
        ? medias
        : filterList?.flatMap((filterOutput) => {
            let partialMedia: Partial<IMediaForMediaList> = {};
            if (filterOutput?.mediaVisibility)
              partialMedia.visibility = filterOutput.mediaVisibility;
            if (filterOutput?.resourceType)
              partialMedia.type = filterOutput.resourceType;

            // lodash filter(): https://lodash.com/docs/4.17.15#filter
            // combine the filtered list into one list
            return filter(medias, partialMedia);
            // warning: this logic could potentially have duplicates
            // needs thorough testing, if it does happen we can
            // simply add a function after this filter logic that
            // removes duplicates
          });

    // filter by search input field
    medias = this.filterService.filterListByName(
      searchText ?? '',
      filteredMedias
    );

    return medias;
  }

  /**
   * Computed property of Media, derived from media.visibility and
   * media.resourceType
   *
   * @param media
   * @returns string
   */
  getReadableType(media: MediaForMediaListFragment) {
    if (
      media.visibility === MediaVisibilityType.Default &&
      media.type === ResourceType.Image
    ) {
      return MediaReadableType.IMAGE;
    } else if (
      media.visibility === MediaVisibilityType.Default &&
      media.type === ResourceType.Video
    ) {
      return MediaReadableType.VIDEO;
    } else if (
      media.visibility === MediaVisibilityType.Template &&
      media.type === ResourceType.Image
    ) {
      return MediaReadableType.IMAGE_TEMPLATE;
    } else if (
      media.visibility === MediaVisibilityType.Template &&
      media.type === ResourceType.Video
    ) {
      return MediaReadableType.VIDEO_TEMPLATE;
    } else if (
      media.visibility === MediaVisibilityType.Playlist &&
      media.type === ResourceType.Image
    ) {
      return MediaReadableType.PLAYLIST_IMAGE;
    } else if (
      media.visibility === MediaVisibilityType.Playlist &&
      media.type === ResourceType.Image
    ) {
      return MediaReadableType.PLAYLIST_VIDEO;
    } else {
      // if non of the combinations above
      return MediaReadableType.UNKNOWN;
    }
  }

  /**
   * Saves media to our database, usually after uploading
   * the media to cloudinary hence the input is similar to
   * the shape of the cloudinary response.
   * - runs refetchQueries()
   *
   * @param input - ISaveMediaInput
   * @returns
   */
  saveMedia(input: SaveMediaInput) {
    return this.saveMediaGQL.mutate({ input }).pipe(
      map((result) => {
        setTimeout(() => {
          this.refetchQueries();
        }, 1500);

        return result;
      })
    );
  }

  /**
   * Deletes medias by list of ids.
   * After doing the mutation this function does the following:
   * - refetches all the media queries
   * - shows a toaster
   *
   * @param ids - list of media ids
   * @returns observable for the mutation
   */
  deleteMedia(ids: string[]) {
    return this.deleteMediaListGQL.mutate({ ids }).pipe(
      map(async (result, loading) => {
        const { data, errors } = result;
        if (data?.deleteMediaList.isSuccessful) {
          // TODO (04-27-2023): go back and fix the refetches below later
          // we recently figured out a better way to refetch

          // refetch
          await this.refetchQueries();

          // refresh folder list
          this.refetchFolderServiceQueries();

          this.toasterService.clear();
          this.toasterService.success('DELETE_MEDIA_SUCCESS');

          return result;
        } else {
          // TODO: properly handle errors
          console.error(errors);
        }
      })
    );
  }

  /**
   * Moves medias to the folder using their ids.
   * After doing the mutation this function does the following:
   * - refetches all the media queries
   * - shows a toaster
   *
   * @param folderId - id of the folder where medias will be moved to
   * @param mediaIds - id of the medias that will be moved
   * @returns
   */
  moveMediaToFolder({ folderId, mediaIds }: MoveMediaFolderInput) {
    return this.moveMediaFolderGQL
      .mutate({ input: { folderId, mediaIds } })
      .pipe(
        map(async (result) => {
          const { data, errors } = result;

          if (data?.moveMediaFolder?.isSuccessful) {
            // this.datatableLoading = false;
            this.toasterService.clear();
            this.toasterService.success('MOVE_MEDIA_FOLDER_SUCCESS');

            // refresh media list
            await this.refetchQueries();

            // refresh folder list
            this.refetchFolderServiceQueries();

            // clear selected medias
            // this.resetSelectedMediaList();

            return result;
          } else {
            // TODO: properly handle errors
            console.error(errors);
          }
        })
      );
  }

  /**
   * Opens a modal to confirm deleting the media. Depending on where the media(s)
   * it will either:
   * 1) move the media(s) to trash
   * 2) delete the media permanently
   *
   * It will choose (2) if the media(s) are under the trash folder already
   *
   * @param mediaList - media(s) that will be deleted or moved to trash
   * @param mediaFolderId - the folder where the media(s) are in
   * @returns
   */
  async openDeleteMediaDialog({
    mediaList,
    mediaFolderId,
  }: {
    mediaList: Media[];
    mediaFolderId: string | null;
  }) {
    const ids = mediaList.map((x) => x.id);

    const modal = this.modalService.open(DeleteMediaDialogComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.selectedMList = mediaList;
    modal.componentInstance.isDeleteAllSelected = mediaList.length > 1;

    // get folder service
    const folderService = await lastValueFrom(
      this.folderService$.pipe(take(1))
    );

    // get or create trash folder
    const trashFolder = await folderService.getTrashFolder();
    if (!trashFolder) return;

    // check ancestor folders if the current selected folder is in
    // the trash folder
    const ancestorFolders = await folderService.getFullPathListById(
      mediaFolderId
    );
    const isInTrashFolder = ancestorFolders?.find(
      ({ id }) => id === trashFolder.id
    )
      ? true
      : false;

    modal.componentInstance.isPermanentDelete = isInTrashFolder;

    await modal.result
      .then(async () => {
        // if media(s) are in the trash folder
        // then permanently delete media
        if (isInTrashFolder) {
          await lastValueFrom(this.deleteMedia(ids));
        } else {
          // if media(s) are not in the trash folder
          // then move media(s) to the trash folder
          await lastValueFrom(
            this.moveMediaToFolder({
              folderId: trashFolder.id,
              mediaIds: ids,
            })
          );
        }
      })
      .catch(() => {});
  }

  async refetchQueries() {
    await this.getMediaByProfileQuery?.refetch();
    await this.getMediaByUserQuery?.refetch();
  }

  resetQueries() {
    // console.log('RESET QUERIES'); // DEBUG
    this.getMediaByProfileQuery = undefined;
    this.getMediaByUserQuery = undefined;
  }

  refetchFolderServiceQueries() {
    this.folderService$
      .pipe(
        take(1),
        tap((folderService) => {
          folderService.refetchQueries();
        })
      )
      .subscribe();
  }

  setFolderIds(folderIds: string[]) {
    this.folderIds.set(folderIds);
  }
}
