import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Folder } from '@designage/gql';
import { TranslateModule } from '@ngx-translate/core';
import { FormDialogComponent } from './form-dialog.component';
import { FolderTreeComponent } from '../folder/folder-tree/folder-tree.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormDialogComponent,
    FolderTreeComponent,
  ],
  selector: 'move-folder-to-folder-dialog',
  template: `
    <app-form-dialog
      headerText="MOVE_FOLDER"
      dismissButtonText="CANCEL"
      closeButtonText="MOVE"
      [values]="selectedDestinationFolderId"
      [valid]="selectedDestinationFolderId !== undefined"
    >
      <div class="p-3">
        {{ 'MOVE_FOLDER_INFO' | translate }}
      </div>
      <hr />
      <!-- Source folders -->
      <div class="card">
        <h5 class="card-header">{{ 'FOLDER_SELECTED_FOLDER' | translate }}</h5>
        <div class="card-body p-3">
          <p *ngFor="let folder of selectedFolders">{{ folder.name }}</p>
        </div>
      </div>

      <!-- Target folder -->
      <div class="card">
        <h5 class="card-header">{{ 'FOLDER_TARGET_FOLDER' | translate }}</h5>
        <div class="card-body">
          <app-folder-tree
            [showNodeMenu]="false"
            [showTrashFolderTree]="false"
            [removeFolderIds]="selectedFolderIds"
            (selectFolderId)="selectedDestinationFolderId = $event"
          >
          </app-folder-tree>
        </div>
      </div>
    </app-form-dialog>
  `,
  styles: [
    `
      .grid-container {
        display: grid;
        grid-template-areas: 'gFolderTree gSelectedFolder';
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }

      .grid-folder-tree {
        grid-area: gFolderTree;
      }

      .grid-selected-folder {
        grid-area: gSelectedFolder;
      }

      h5 {
        text-align: center;
      }
    `,
  ],
})
export class MoveFolderToFolderDialogComponent {
  selectedDestinationFolderId: string | null = null;
  selectedFolders: Pick<Folder, 'id' | 'name'>[] = [];

  get selectedFolderIds(): string[] {
    return this.selectedFolders.map(({ id }) => id);
  }
}
