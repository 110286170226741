import { Injector, Type, inject } from '@angular/core';
import { Observable, defer } from 'rxjs';

export function lazyLoadService<T>(
  loader: () => Promise<Type<T>>
): Observable<T> {
  const injector = inject(Injector);

  return defer(() => {
    return loader().then((service) => injector.get(service));
  });
}
